















































































































































































@import "../../../../assets/css/exper/experiDetail";

.table {
  width: 820px;

  tr td:first-child {
    width: 180px;
  }
}
